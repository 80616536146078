import {
  Alert,
  Badge,
  Breadcrumb,
  Button, Card, Col, Image, Row, Stack,
} from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AnnouncementList from '../components/announcement/AnnouncementList';
import useWithLoading from '../hooks/useWithLoading';
import { getEvent } from '../services/events';
import { AuthContext } from '../context/AuthContextProvider';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import Loading from '../components/common/Loading';
import { getFormattedNZTDateString } from '../utils/AnnouncementUtils';
import EventActions from '../components/dashboard/EventActions';
import ResultsCard from '../components/dashboard/student/ResultsCard';
import { STUDENT_ROLE } from '../utils/roleComputation';
import BoxShadowCard from '../components/common/layout/BoxShadowCard';

function EventViewPage() {
  const { eventCode } = useParams();
  const [event, setEvent] = useState();
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const [loading, withLoading] = useWithLoading();
  const history = useHistory();

  useEffect(() => {
    withLoading(
      getEvent(auth, eventCode),
      (res) => setEvent(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>{eventCode}</Breadcrumb.Item>
      </Breadcrumb>
      <Loading loading={loading}>
        {event && (
        <>
          <h1 className="display-6 fw-bold mt-2">{event.eventName}</h1>
          <small>
            Event Date:
            {getFormattedNZTDateString(event.eventDate)}
          </small>
          <br />
          <span
            className="small"
            style={{ color: 'red' }}
          >
            Registration deadline:
            {' '}
            {getFormattedNZTDateString(event.registrationDueDate)}
          </span>
          <br />
          <Row>
            <Col xs={12} md={8}>
              <p className="lead">
                {event?.shortDescription}
                {' '}
                <br />
              </p>
            </Col>
            <Col xs={12} md={4} className="mb-4">
              <BoxShadowCard>
                <small className="text-muted fw-bold">Actions</small>
                <Card style={{ border: 'none' }}>
                  <EventActions event={event} />
                </Card>
                {auth?.role === STUDENT_ROLE && (
                <>
                  <small className="text-muted fw-bold">
                    Result (Only applicable for
                    competitions)
                  </small>
                  <Card style={{ border: 'none' }}>
                    <ResultsCard event={event} />
                  </Card>
                </>
                )}
              </BoxShadowCard>
            </Col>
          </Row>
          {event?.announcementList?.length > 0
                            && (
                            <small className="text-muted small fw-bold">
                              Related Announcements
                            </small>
                            )}
          <AnnouncementList eventName={event?.eventName} announcements={event?.announcementList} detailedView />
        </>
        )}
      </Loading>
    </>
  );
}

export default EventViewPage;
