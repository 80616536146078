import { ListGroup, ListGroupItem } from "react-bootstrap"

export default function ScrollSpyNavigation({ list, labelRenderer }) {
  return (
    <div className={'rounded-3 p-3'} style={{ border: 'solid 1px silver' }}>
      <p className={'lead fw-bold'}>Navigation</p>
      <ListGroup>
        {list.map((item) => (
            <ListGroupItem
              onClick={() =>
                document
                  .getElementById(item.key)
                  .scrollIntoView({ behavior: 'smooth', block: 'start' })
              }
            >
              {labelRenderer(item)}
            </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  )
}
