import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert, Button, Card, Col, Row,
} from 'react-bootstrap';
import useWithLoading from '../../hooks/useWithLoading';
import { AuthContext } from '../../context/AuthContextProvider';
import { declineEvent, getRegistrationStatus, releaseEvent } from '../../services/events';
import { ALERT_ERROR_MESSAGE } from '../../utils/ResponseUtils';
import Loading from '../common/Loading';
import { ADMIN_ROLE, STUDENT_ROLE, TEACHER_ROLE } from '../../utils/roleComputation';

function StudentActions({ event, readOnly }) {
  const [loading, withLoading] = useWithLoading();
  const { authRetrievedProfile } = useContext(AuthContext);
  const [registrationStatus, setRegistrationStatus] = useState();
  const history = useHistory();
  const REGISTERED = 'Registered';
  const NOT_REGISTERED = 'Not Registered';
  const DECLINED = 'Declined';

  const fetchEventStatus = () => {
    withLoading(
      getRegistrationStatus(authRetrievedProfile, event.eventCode),
      (res) => setRegistrationStatus(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  };

  useEffect(() => {
    fetchEventStatus();
  }, []);

  const canRegister = new Date() <= new Date(event.registrationDueDate);
  const onRegister = () => history.push({ pathname: '/Payment', state: { eventCode: event.eventCode } });

  const onDecline = () => {
    if (!window.confirm('Are you sure?')) return;
    if (!window.confirm("Are you really sure? (You can't revert this action)")) return;
    withLoading(
      declineEvent(authRetrievedProfile, event.eventCode),
      // apparently causes full page reload, which is nasty, but I couldn't find a better alternative with react-router-dom-v5
      () => history.go(0),
      ALERT_ERROR_MESSAGE,
    );
  };

  function RegisteredDisplay() {
    return (
      <span className="text-center p-3 fw-bold text-success">
        ✅ You are registered!
      </span>
    );
  }

  function OutDatedEventDisplay() {
    return (
      <span className="text-center p-3 fw-bold">
        Registration is closed 😔
      </span>
    );
  }

  function NotRegisteredDisplay() {
    if (readOnly) {
      return <span className="text-center p-3 fw-bold">👆 Join now!</span>;
    }
    return (
      <>
        <Button className="w-100 mb-2" variant="success" onClick={onRegister}>
          Register 🐵
        </Button>
        <Button className="w-100" variant="danger" onClick={onDecline}>
          Decline 🙊
        </Button>
      </>
    );
  }

  function DeclinedDisplay() {
    return (
      <span className="text-center p-3 fw-bold text-danger">
        🙈 You've declined this event
      </span>
    );
  }

  function ErrorDisplay() {
    return (<>Hmmm you shouldn't be seeing this, let us know at contact@nzpmc.com if you keep seeing this.</>);
  }

  function DisplaySwitcher() {
    if (registrationStatus === REGISTERED) {
      return <RegisteredDisplay />;
    }
    if (registrationStatus === DECLINED) {
      return <DeclinedDisplay />;
    }
    if (registrationStatus === NOT_REGISTERED) {
      return canRegister ? <NotRegisteredDisplay /> : <OutDatedEventDisplay />;
    }
    return <ErrorDisplay />;
  }

  return (
    <Loading loading={loading}>
      <DisplaySwitcher />
    </Loading>
  );
}

function TeacherActions({ eventCode, readOnly }) {
  const history = useHistory();

  const navigate = () => {
    history.push(`/dashboard/registeredStudents/${eventCode}`);
  };

  function RegistrationSummary() {
    if (readOnly) {
      return (<span className="text-center p-3 fw-bold text-success">See registration summary</span>);
    }
    return (<Button variant="success" onClick={navigate}>Check students from your school</Button>);
  }

  return (<RegistrationSummary />);
}

function AdminActions({ event }) {
  return (
    <>
      {!event.isReleased ? <ReleaseEvent eventCode={event.eventCode} eventName={event.eventName} />
        : (
          <Card className="p-3 text-center text-success fw-bold">
            {`✅ Event ${event.eventName} has been released`}
          </Card>
        )}
    </>
  );
}

function ReleaseEvent({ eventCode, eventName }) {
  const [loading, withLoading] = useWithLoading();
  const { authRetrievedProfile } = useContext(AuthContext);
  const history = useHistory();

  const onClick = () => {
    withLoading(
      releaseEvent(authRetrievedProfile, eventCode),
      (res) => {
        alert('Event released for registration!');
        // apparently causes full page reload, which is nasty, but I couldn't find a better alternative with react-router-dom-v5
        history.go(0);
      },
      ALERT_ERROR_MESSAGE,
    );
  };

  return (
    <Loading loading={loading}>
      <Button className="w-100" variant="success" onClick={onClick}>
        ⚒ Release
        {' '}
        <b>{eventName}</b>
        {' '}
        event (Admin)
      </Button>
    </Loading>
  );
}

export default function EventActions({ event, readOnly }) {
  const { authRetrievedProfile } = useContext(AuthContext);
  if (authRetrievedProfile?.role === ADMIN_ROLE) {
    return <AdminActions event={event} />;
  }
  if (authRetrievedProfile?.role === STUDENT_ROLE) {
    return (<StudentActions event={event} readOnly={readOnly} />);
  }
  if (authRetrievedProfile?.role === TEACHER_ROLE) {
    return (<TeacherActions eventCode={event?.eventCode} readOnly={readOnly} />);
  }
  const history = useHistory()
  return (<Button href={'#'} onClick={()=>history.push("/login")}>Sign up now!</Button>);
}
