import { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Card, Col, ListGroup, ListGroupItem,
  Row,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContextProvider';
import useWithLoading from '../../hooks/useWithLoading';
import { getAllAnnouncements } from '../../services/announcement';
import { getEvents } from '../../services/events';
import { getFormattedNZTDateString } from '../../utils/AnnouncementUtils';
import { ALERT_ERROR_MESSAGE } from '../../utils/ResponseUtils';
import { isStudent, isTeacher } from '../../utils/roleComputation';
import AnnouncementList from '../announcement/AnnouncementList';
import Loading from '../common/Loading';
import EventActions from '../dashboard/EventActions';
import './EventView.css';
import StudyImage from './study.png'
import EventCard from './EventCard';

function EventList() {
  const [events, setEvents] = useState();
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const [loading, withLoading] = useWithLoading();

  useEffect(() => {
    withLoading(
      getEvents(auth),
      (res) => setEvents(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  const [loading2, withLoading2] = useWithLoading();
  const [announcements, setAnnouncements] = useState();

  const { authRetrievedProfile } = useContext(AuthContext);

  useEffect(() => {
    withLoading2(
      () => getAllAnnouncements(authRetrievedProfile?.email, authRetrievedProfile?.code),
      (res) => setAnnouncements(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  return (
    <>
      <Loading loading={loading}>
        <PleaseReadFaq />
        <Row>
          <Col md={12} xl={6}>
            <p className="fw-bold text-muted">
              Recent Announcements (
              <Link to="/announcements">View all</Link>
              )
            </p>
            <Loading loading={loading2} message="Retrieving announcements">
              <AnnouncementList announcements={announcements?.slice(0,5)} />
            </Loading>
            <br/>
            <br/>
          </Col>
          <Col md={12} xl={6}>
            <p className="fw-bold text-muted">{new Date().getFullYear()} Events
              (<Link to="/events">View all</Link>)
            </p>
            <Row>
              {events?.filter(event => new Date(event?.eventDate).getFullYear() === new Date().getFullYear())
                .map(((event, index) => <EventCard event={event} index={index + 1}/>))}
            </Row>
          </Col>
        </Row>
      </Loading>
    </>
  );
}

function PleaseReadFaq() {
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const history = useHistory();
  return (isStudent(auth) || isTeacher(auth)) && (
    <Row>
      <Col>
        <Alert variant="info">
          New here? Please read the <a href="#" onClick={()=>history.push('/faq')}>Frequently Asked Questions
        </a> section!
        </Alert>
      </Col>
    </Row>
  );
}


export default EventList;
