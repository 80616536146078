import { useContext, useEffect, useState } from 'react';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { PageContext } from '../context/PageContextProvider';
import AnnouncementList from '../components/announcement/AnnouncementList';
import { getAllAnnouncements } from '../services/announcement';
import CreateAnnouncementButton from '../components/announcement/CreateAnnouncementButton';
import Loading from '../components/common/Loading';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import useWithLoading from '../hooks/useWithLoading';
import { AuthContext } from '../context/AuthContextProvider';
import ScrollSpyNavigation from '../components/common/layout/ScrollSpyNavigation.js'

const pageMetadata = {
  titleLH: 'Our',
  titleRH: 'Announcements',
  body: "Don't miss any announcements! You need to refresh the page to see new comments.",
};

function AnnouncementListPage() {
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => setPageMetadata(pageMetadata), []);
  const history = useHistory();

  const [loading, withLoading] = useWithLoading();
  const [announcements, setAnnouncements] = useState([]);

  const { authRetrievedProfile } = useContext(AuthContext);

  useEffect(() => {
    withLoading(
      () => getAllAnnouncements(authRetrievedProfile?.email, authRetrievedProfile?.code),
      (res) => setAnnouncements(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  function categoriseAnnouncementsByMonths(){
    return Object.values(announcements.reduce((result, announcement) => {
      const announcementCreationDate = new Date(announcement?.creationDate);
      const year = announcementCreationDate.getFullYear()
      const month = announcementCreationDate.getMonth()

      const key = `${year}_${month}`;
      result[key] = result[key] || {key, month, year, announcements: []};
      result[key].announcements.push(announcement);
      return result;
    },{})).sort((a,b) => {
      if (b.year !== a.year) {
        return (b.year - a.year)
      }
      return b.month - a.month
    });
  }

  const announcementsByMonths = categoriseAnnouncementsByMonths(announcements) || []
  const months = [ "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December" ];


  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Announcements</Breadcrumb.Item>
      </Breadcrumb>
      <Loading loading={loading} message="Retrieving all the announcements">
        <Row>
          <Col md={12} lg={3}>
          <ScrollSpyNavigation
            list={announcementsByMonths}
            labelRenderer={(category) => `${months[category.month]}, ${category.year}`}
          />
          </Col>
          <Col md={12} lg={9}>
            {announcementsByMonths.map(category => {
              return (<>
                <div className={"lead my-3"} id={category.key}>{months[category.month]}, {category.year}</div>
                <AnnouncementList announcements={category.announcements} />
              </>)
            })}
          </Col>
        </Row>
      </Loading>
      <br />
      <CreateAnnouncementButton />
    </>
  );
}

export default AnnouncementListPage;
