import EventList from '../components/events/EventList';
import { useContext, useEffect, useState } from 'react';
import { PageContext } from '../context/PageContextProvider';
import { useHistory } from 'react-router-dom';
import useWithLoading from '../hooks/useWithLoading';
import { AuthContext } from '../context/AuthContextProvider';
import { getAllAnnouncements } from '../services/announcement';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import { Breadcrumb, Row } from 'react-bootstrap';
import Loading from '../components/common/Loading';
import AnnouncementList from '../components/announcement/AnnouncementList';
import CreateAnnouncementButton from '../components/announcement/CreateAnnouncementButton';
import { getEvents } from '../services/events';
import EventCard from '../components/events/EventCard';

const pageMetadata = {
  titleLH: 'NZPMC',
  titleRH: 'Events',
  body: "See all NZPMC events, including competitions and workshops",
}
const EventListPage = ()=>{
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => setPageMetadata(pageMetadata), []);
  const history = useHistory();

  const [loading, withLoading] = useWithLoading();
  const [events, setEvents] = useState();

  const { authRetrievedProfile:auth } = useContext(AuthContext);

  useEffect(() => {
    withLoading(
      getEvents(auth),
      (res) => setEvents(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Events</Breadcrumb.Item>
      </Breadcrumb>
      <Loading loading={loading} message="Retrieving all the events">
        <Row>
          {events?.map(((event, index) => <EventCard event={event} index={index + 1}/>))}
        </Row>
      </Loading>
      <br/>
      <CreateAnnouncementButton/>
    </>
  );
}

export default EventListPage
