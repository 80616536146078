import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContextProvider';
import { Card, Col, ListGroupItem } from 'react-bootstrap';
import StudyImage from './study.png';
import { getFormattedNZTDateString } from '../../utils/AnnouncementUtils';

export default function EventCard({ event, isSimple=false}) {
  const history = useHistory();
  const { authRetrievedProfile: auth } = useContext(AuthContext);

  const handleEventCardButtonClick = () => {
    history.push(`/dashboard/${event?.eventCode}`);
  };

  const daysBeforeRegistrationClosure = ((new Date(event?.registrationDueDate))/1000/60/60/24 - new Date()).toFixed(0)
  const isPastRegistration = daysBeforeRegistrationClosure < -1

  if(isSimple){
    return (
      <ListGroupItem action onClick={handleEventCardButtonClick}>
        {event?.eventName}
      </ListGroupItem>
    )
  }

  return (
    <Col lg={4} className="mb-3">
      <Card className="h-100 event-card bg-black" onClick={handleEventCardButtonClick}>
        <Card.Img src={StudyImage} className={"rounded-bottom-0"} style={{objectFit: "cover"}} height={"150px"}/>
        <Card.ImgOverlay>
          <Card.Title style={{color: 'white'}}>
            {event?.eventName}
          </Card.Title>
          <Card.Text style={{color: 'whitesmoke'}}>
            {getFormattedNZTDateString(event?.eventDate)}
          </Card.Text>
        </Card.ImgOverlay>
        <Card.Footer>
          <span className={"text-white"}>
            {!isPastRegistration ? <>
              Sign up in <strong>{daysBeforeRegistrationClosure}</strong> days!
            </> : <>Registration Closed
            </>}
          </span>
        </Card.Footer>
      </Card>


    </Col>
  );
}
