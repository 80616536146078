import { Container } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Footer, Instruction, Navigation } from './SiteLevelComponents';
import { AuthContextProvider } from './context/AuthContextProvider';
import { PageContextProvider } from './context/PageContextProvider';
import { SessionContextProvider } from './context/SessionContextProvider';
import AccountPage from './pages/AccountPage';
import AnnouncementListPage from './pages/AnnouncementListPage';
import AnnouncementPage from './pages/AnnouncementPage';
import CreateAnnouncementPage from './pages/CreateAnnouncementPage';
import CreateEventPage from './pages/CreateEventPage';
import DashboardPage from './pages/DashboardPage';
import EventViewPage from './pages/EventViewPage';
import LoginPage from './pages/LoginPage';
import PaymentCancelled from './pages/PaymentCancelled';
import PaymentFailure from './pages/PaymentFailure';
import Payment from './pages/PaymentPage';
import RegisteredStudentsPage from './pages/RegisteredStudentsPage';
import StudentSignupPage from './pages/StudentSignupPage';
import TeacherSignupPage from './pages/TeacherSignupPage';
import ThankYou from './pages/ThankYou';
import FAQPage from './pages/FAQPage';
import EventListPage from './pages/EventListPage';

function App() {
  return (
    <div className="App">
      <div className="Main">
        <SessionContextProvider>
          <AuthContextProvider>
            <Navigation />
            <PageContextProvider>
              <Instruction />
              <Container className="my-3">
                <Switch>
                  <Route path="/faq">
                    <FAQPage />
                  </Route>
                  <Route exact path="/student">
                    <StudentSignupPage />
                  </Route>
                  <Route exact path="/teacher">
                    <TeacherSignupPage />
                  </Route>
                  <Route path="/ThankYou">
                    <ThankYou />
                  </Route>
                  <Route path="/Cancelled">
                    <PaymentCancelled />
                  </Route>
                  <Route path="/Failure">
                    <PaymentFailure />
                  </Route>
                  <Route path="/Payment">
                    <Payment />
                  </Route>
                  <Route path="/login">
                    <LoginPage />
                  </Route>
                  <Route path="/account">
                    <AccountPage />
                  </Route>
                  <Route exact path="/dashboard/:eventCode">
                    <EventViewPage />
                  </Route>
                  <Route exact path="/dashboard/registeredStudents/:eventCode">
                    <RegisteredStudentsPage />
                  </Route>
                  <Route exact path="/announcements">
                    <AnnouncementListPage />
                  </Route>
                  <Route exact path="/events">
                    <EventListPage />
                  </Route>
                  <Route exact path="/announcements/:announcementId">
                    <AnnouncementPage />
                  </Route>
                  <Route path="/admin/createAnnouncement">
                    <CreateAnnouncementPage />
                  </Route>
                  <Route path="/admin/createEvent">
                    <CreateEventPage />
                  </Route>
                  <Route exact path="/">
                    <DashboardPage />
                  </Route>
                </Switch>
              </Container>
            </PageContextProvider>
          </AuthContextProvider>
        </SessionContextProvider>
      </div>
      <Footer />
    </div>
  );
}

export default App;
