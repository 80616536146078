import { ListGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { getNumberOfComments } from '../../pages/AnnouncementPage';
import { getDateStringFromAnnouncement, removeHtmlTags } from '../../utils/AnnouncementUtils';


export default function AnnouncementList({ announcements, detailedView=false }) {
  const history = useHistory();

  return (
    <ListGroup>
      {announcements ? announcements?.map((announcement) => {
        return (
          <ListGroup.Item
            key={announcement.id}
            onClick={() => window.open(`/announcements/${announcement.id}`, "_blank")}
            action
            className="d-flex justify-content-between align-items-start"
          >
            {detailedView ? <DetailedAnnouncementItem announcement={announcement} /> : <SimpleAnnouncementItem announcement={announcement} />}
          </ListGroup.Item>
        )
      }) : <div>No announcements</div>}
    </ListGroup>
  );
}

function SimpleAnnouncementItem({announcement}) {
  const title = announcement.title
  const numberOfComments = (announcement?.comments && getNumberOfComments(announcement.comments)) || 0
  const numberOfAttachments = announcement.attachmentURLs?.length || 0

  return (
    <>
      <div className="ms-2 me-auto text-truncate" style={{ width: '100%' }}>
        <div className="fw-bold text-truncate d-flex justify-content-between">
          <div>
            {title}
            {numberOfAttachments !==0 && <span className="fw-bold small ms-2" style={{ color: "mediumvioletred" }}><i class="bi bi-paperclip"></i></span>}
          </div>
          <div>
            {numberOfComments !== 0 && <span className="fw-bold small ms-2" style={{ color: 'darkgreen' }}><i className="bi bi-chat"></i> {numberOfComments} replies</span>}
          </div>
        </div>
      </div>
    </>
  );
}


function DetailedAnnouncementItem({announcement}) {
  const title = announcement.title
  const numberOfComments = (announcement?.comments && getNumberOfComments(announcement.comments)) || 0
  const numberOfAttachments = announcement.attachmentURLs?.length || 0
  const body = removeHtmlTags(announcement.body).substring(0,500)
  const dateString = getDateStringFromAnnouncement(announcement)
  return (<>
    <div className="ms-2 me-auto" style={{ width: '100%' }}>
      <div className="fw-bold text-truncate d-flex justify-content-between mb-1">
        <div>
          {title}
          {numberOfAttachments !== 0 && <span className="fw-bold small ms-2" style={{ color: "mediumvioletred" }}><i
            className="bi bi-paperclip"></i></span>}
        </div>
        <div>
          {numberOfComments !== 0 && <span className="fw-bold small ms-2" style={{ color: 'darkgreen' }}><i
            className="bi bi-chat"></i> {numberOfComments} replies</span>}
        </div>
      </div>
      <div>
        {body}
      </div>
    </div>
  </>)
}
