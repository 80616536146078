import React, { useContext, useState } from 'react';
import { Accordion, Badge, Button, ButtonGroup, Form, InputGroup } from 'react-bootstrap';
import { AuthContext } from '../../../context/AuthContextProvider';
import { Expand, ExpandWrapper } from '../../common/layout/Expand';

export default function Comment({
  comment, addReply,
  // only top level comments are replyable
  topLevelComment: isTopLevelComment
}) {
  const {authRetrievedProfile} = useContext(AuthContext);

  const NameBadge = () => {
    const roleColour = (role) => (role === 'Admin' ? 'danger' : (role === 'Teacher' ? 'warning' : 'success'));
    return (
      <>
        <div className="fw-bold" style={{
          display: 'inline',
          marginRight: '10px'
        }}>{comment.name}</div>
        <Badge bg={roleColour(comment.role)} style={{ display: 'inline' }}>
          {comment.role}
        </Badge>
      </>
    );
  }

  const ChildComments = () => (
    <>
      {comment.replies && comment.replies.length > 0 && (
        <div>
          {comment.replies.map((reply) => (
            <Comment
              key={reply.id}
              comment={reply}
              addReply={addReply}
              isTopLevelComment={false}
            />
          ))}
        </div>
      )}
    </>
  )

  return (
    <div className="text-truncate p-2 my-1" style={{ border: isTopLevelComment ? '1px solid #ccc' : 'none' }}>
      <div className="ms-2 me-auto" style={{}}>
        <NameBadge/>
        <div style={{
          overflowWrap: 'break-word',
          whiteSpace: 'normal'
        }}>{comment.content}</div>
        </div>
        {(isTopLevelComment && comment.replies.length > 0) &&
          <details style={{
            margin: '10px'
          }}>
            <summary className={"text-muted"}>{`${comment.replies.length} replies`}</summary>
            <div>
              <ChildComments/>
            </div>
          </details>
        }
      {(isTopLevelComment && authRetrievedProfile) && <ReplyBox addReply={addReply} comment={comment} />}
    </div>
  );
}

function ReplyBox({
  comment, addReply
}) {
  const [replyContent, setReplyContent] = useState();
  const handleReplyChange = (e) => setReplyContent(e.target.value);

  const submitReply = () => {
    addReply(comment, replyContent)
  };

  return (
    <InputGroup className="mt-2">
      <Form.Control
        placeholder="Reply to this comment..."
        value={replyContent}
        onChange={handleReplyChange}
        style={{
          marginLeft: '5px',
          marginRight: '10px',
        }}
      />
      <ButtonGroup>
        <Button variant="outline-secondary" size="sm" onClick={submitReply}>
          Comment
        </Button>
      </ButtonGroup>
    </InputGroup>
  );
}
